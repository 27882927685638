.sent-agreements {
    .agreement-list-container {
        .block.block-rounded {
            border-radius: 0;
            box-shadow: none;
            background-color: #fafafa;
        }
    }

    table {
        tr {
            td.MuiTableCell-body {
                padding: 16px 8px;
            }
        }
    }
}
