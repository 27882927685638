.block {
    &.block-rounded {
        &.with-border {
            border-top: 10px solid $navy-seal;

            @include respond_to_smaller_than_desktop {
                border-top: 10px solid $white;
            }
        }
    }

    .block-footer {
        display: flex;
        margin: 0 -20px;
        border-top: 1px solid rgba(196, 196, 196, 0.3);
        padding: 8px;
    }
}
