main form button.hidden-save, button.hidden-save {
    display: none !important;
    width: 0;
    height: 0;
}

.header-user-menu {
    .MuiMenu-list {
        .MuiListItem-root {
            color: $black;
            i.svg-icon {
                margin-right: 10px;
                background-color: $black;
            }

            &:hover {
                i.svg-icon {
                    background-color: $black;
                }
            }
        }
    }
}

.hidden-mobile {
    @include respond_to_smaller_than_desktop {
        display: none;
    }
}

.mobile-only {
    display: none;

    @include respond_to_smaller_than_desktop {
        display: block;
    }
}
