.btn {
    border-radius: 10px;
    padding: 9px 50px;
    text-transform: uppercase;
    font-family: $heading-font-family;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;

    @include respond_to_sm {
        font-size: 14px;
        height: 48px;
    }

    @include respond_to_md {
        font-size: 14px;
        height: 48px;
    }

    &.btn-primary, &.btn-success {
        background-color: $background-color-blue;
        border: 1px solid $background-color-blue;
        color: $white;

        &:not(:disabled):hover {
            background-color: $white;
            color: $background-color-blue;
        }
    }

    &.btn-danger {
        background-color: $white;
        color: $danger-red;
        border: 1px solid $danger-red;
        box-sizing: border-box;

        &:not(:disabled):hover {
            color: $white;
            background-color: $danger-red;
        }
    }

    &.btn-secondary {
        background-color: $grey-2;
        color: $brand-primary;
        border: 1px solid $grey-3;
        box-sizing: border-box;

        &:not(:disabled):hover {
            color: $white;
            background-color: $brand-primary;
        }
    }

    &.no-border {
        border: none;
        border-radius: .2rem;
    }

    &.btn-info {
        color: $brand-primary;
        background-color: $white;
        border: 1px solid $grey-3;
        box-sizing: border-box;

        &:not(:disabled):hover {
            color: $white;
            background-color: $brand-primary;
        }
    }
}

.btn.icon-btn {
    padding: 8px;
    color: $grey-5;
    border-radius: 10px;
    border: 2px solid $grey-5;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;

    &:not(:disabled):hover {
        background-color: $grey-5;
        color: $white;

        i.svg-icon {
            color: $grey-5;
            background-color: $white;
        }
    }

    i.svg-icon {
        width: 20px;
        height: 20px;
        -webkit-mask-size: 20px 20px;
        color: $grey-5;
        border-radius: 2px;
        mask-size: 20px 20px;

        &:hover {
            color: $grey-5;
            background-color: $white;
        }
    }
}

.btn.btn-float {
    border-radius: 100%;
    border: none;
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 100px;
    right: 15px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

    &.icon-btn {
        color: $white;
        background-color: $brand-primary;

        i.svg-icon {
            -webkit-mask-size: 20px 20px;
            background-color: $white;
        }
    }
}

.btn.btn-fb {
    display: flex;
    background-color: #1877f2 !important;
    color: $white;
    width: 100%;
    justify-content: center;
    gap: 10px;
    align-items: center;
    font-size: 12px;
}

button.btn.btn-save-attachments {
    @include respond_to_smaller_than_desktop() {
        display: none;
    }
}
