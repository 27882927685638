$pagination-box-shadow: 0 6px 12px rgba(41, 43, 50, 0.12);

.ps-pagination {
  .MuiPagination-ul {
    justify-content: center;

    .MuiPaginationItem-root {
      height: 40px;
      margin-left: 10px;
      width: 40px;
    }

    .MuiPaginationItem-ellipsis {
      height: auto;
      width: 20px;
    }

    .MuiPaginationItem-page {
      border-radius: 4px;
      box-shadow: $pagination-box-shadow;
      color: $brand-primary;
      font-family: $subheading-font-family;
      font-size: $button-font-size;
      font-weight: $table-font-weight;

      &.Mui-selected {
        background: $background-color-blue;
        color: $white;
        box-shadow: 0 2px 8px rgba(105, 121, 248, 0.5);
      }
    }

    li:first-child {
      .MuiPaginationItem-root {
        margin-left: 0;
      }
    }

    .MuiPaginationItem-icon {
      height: 16px;
      width: 16px;
    }

    @include respond_to_sm {
      li:not(:first-child):not(:last-child):not(*>.Mui-selected) {
        display: none;
      }

      .MuiPaginationItem-root {
        width: 100px;
      }
    }
  }
}