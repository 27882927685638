//Colors
$brand-primary: #04BAD8;
$white: #ffffff;
$black: #000000;
$lighter-white: #edf0f2;
$lighter-black: #343a40;
$success-green: #4CAF50;;
$danger-red: #db3737;
$grey-1: #979797;
$grey-2: #EFEFEF;
$grey-3: #D8D8D8;
$grey-5: #A3A3A3;
$background-color-blue: #52BAD6;
$navy-seal: #262B5E;
$neutrals-black: #1A1E20;
$neutral-grey-2: #c4c4c4;
$accent: #8280EB;

$heading-font-family: "Poppins";
$subheading-font-family: "Roboto", "Helvetica", "Arial", sans-serif;

$heading-color: $navy-seal;

$button-font-size: 14px;
$button-line-height: 15px;

$badge-font-size: 10px;
$badge-line-height: 15px;

$font-size-small: 12px;
$font-size-small-line-height: 16px;

$h2-size: 36px;
$h2-line-height: 54px;

$h2-size-mobile: 20px;
$h2-line-height-mobile: 32px;

$h3-size: 24px;
$h3-line-height: 36px;

$h4-size: 16px;
$h4-line-height: 24px;

$placeholder-font-size: 14px;
$placeholder-line-height: 16px;
$placeholder-color: #979797;

$subheading-font-size: 14px;
$subheading-font-line-height: 16px;

$table-font-size: 14px;
$table-line-height: 21px;
$table-font-weight: 500;

$grey-border: 2px solid rgba(196, 196, 196, 0.3);
