table {
    @include respond_to_smaller_than_desktop {
        line-height: 24px;
    }

    &.ps-table {
        border: none;

        tr, td, thead, tbody {
            border: none;
        }
    }

    &.total-table {
        font-family: $subheading-font-family;
        font-size: $placeholder-font-size;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: $grey-5;

        td {
            display: table-cell;
            margin-top: 9px;
            padding: 0;

            &.label {
                text-align: left;
            }

            &.amount {
                text-align: right;

                &.discounted {
                    text-decoration: line-through;
                }
            }

            &:last-child {
                text-align: right;
            }
        }
    }

    thead {
        tr {
            th, th.MuiTableCell-head {
                font-weight: 700;
                font-size: 10px;
                line-height: 15px;

                letter-spacing: 0.03em;
                text-transform: uppercase;

                color: $grey-5;
            }
        }
    }

    tbody {
        tr, tr.MuiTableCell-root {
            border: none;

            td, td.MuiTableCell-root {
                border: none;
                font-style: normal;
                font-weight: $table-font-weight;
                font-size: $table-font-size;
                line-height: $table-line-height;

                letter-spacing: 0.01em;
                color: $neutrals-black;

                &.hidden-mobile {
                    @extend .hidden-mobile;
                }

                div.recipient {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    gap: 10px;

                    &.mobile-only {
                        @extend .mobile-only;
                    }
                }

                div.title {

                }
            }
        }
    }
}
