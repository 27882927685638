#main-container {
    .hero.error-hero {
        height: 74vh;

        .hero-inner {
            .bg-body-extra-light {
                background-color: $white;
            }
        }
    }
}
