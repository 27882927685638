.notification-container {
    padding-bottom: 32px;

    ul {
        padding-left: 10px;
        margin-bottom: 0;
    }
}

.MuiPaper-root.MuiAlert-root {
    border-radius: 10px;
    padding: 20px;
}
