.color-brand-primary {
    color: $brand-primary;
}

.bg-brand-primary {
    background-color: $brand-primary;
}

.block.block-rounded {
    border-radius: 0.75rem;
}

.bg-body-brand-primary {
    background-color: $brand-primary;

    h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5 {
        color: $white;
    }

    .text-muted {
        color: $lighter-white !important;
    }
}

.text-muted {
    color: $grey-1;
}

.form-control.aysnc-autocomplete {
    height: 100%;
    padding: 6px 0;
    border: 0;

    &.is-invalid {

        background-image: none;
    }
}

.clickable {
    cursor: pointer;
}
