.page-title-actions {
    display: flex;
    justify-content: flex-end;

    @include respond_to_md {
        justify-content: flex-start;
    }

    @include respond_to_sm {
        justify-content: flex-start;
    }
}
