@mixin respond_to_sm {
  @media (max-width: 576px) { @content }
}

@mixin respond_to_md {
  @media (min-width: 577px) and (max-width: 768px) { @content }
}

@mixin respond_to_lg {
  @media (min-width: 769px) { @content }
}

@mixin respond_to_smaller_than_desktop {
  @media (max-width: 768px) { @content }
}
