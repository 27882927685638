.rhc-container {
    .form-group {
        .DayPickerInput {
            input {
                border: $grey-border;
                box-sizing: border-box;
                border-radius: 5px;
            }
        }
    }

    .form-group:last-child {
        margin: 16px 0;
    }
}
