main.dashboard {
    .ads-container {
        margin-top: 56px;

        @include respond_to_sm {
            display: none;
        }

        @include respond_to_md {
            display: none;
        }
    }
}
