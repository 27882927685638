.forgot-password-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100vw;

    @include respond_to_lg {
        width: 480px;
    }

    @include respond_to_sm {
        padding: 0 20px;
    }

    @include respond_to_sm {
        padding: 0 20px;
    }

    .go-back-container a {
        font-family: $subheading-font-family;
        font-style: normal;
        font-weight: normal;
        font-size: $subheading-font-size;
        line-height: $subheading-font-line-height;
        letter-spacing: 0.01em;
        color: $grey-1;
    }
}
