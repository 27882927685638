main.profile-page {
    .form-group.mobile {
        input.form-control {
            margin-bottom: 5px;
        }

        span.text-muted {
            margin-left: 2px;
        }
    }
}