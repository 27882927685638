.create-agreement {
    @extend .base-agreement;

    .contract-title.form-group, .contract-agreement-details.form-group {
        .MuiInputBase-root {
            border-radius: 4px;
            background-color: white;
        }
    }

    .form-group.secure_agreement_switch {
        display: flex;
        flex-direction: column;

        span.secure_agreement_switch {
            border: 0;
            margin-left: -12px;
        }
    }

    div.or-divider {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 4px 0;
        font-size: 12px;
    }

    @include respond_to_sm {
        .agreement-amounts {
            display: none;
        }
    }

    @include respond_to_md {
        .agreement-amounts {
            display: none;
        }
    }
}
