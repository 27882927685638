form {
    margin-top: 0;

    .form-group {
        margin: 16px 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
