.pills-container {
    display: flex;
    padding: 10px;
    margin: 0 -10px;
    align-items: flex-start;
    align-content: flex-start;
    border-top: 1px solid rgba(196, 196, 196, 0.3);

    .pill {
        display: flex;
        padding: 6px 14px;

        a:hover {
            text-decoration: underline;
        }
    }
}
