.block-card-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;

    a.card-action {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 40px;
        border: 1px solid rgba(196, 196, 196, 0.3);
        border-radius: 10px;
        flex: 1;
        gap: 16px;
    }
}
