.entity-card {
    h3.entity-name {
        margin-bottom: 11px;
    }

    div.card-subtitle {
        &:first-child {
            padding-bottom: 0;
        }

        padding-bottom: 5px;
    }
}

// TODO I've split user-card and recipient-card styles because they might differ going forward. Not sure?
.user-card, .profile-card {
    .block > .block-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 20px;

        .MuiIconButton-root {
            position: absolute;
            top: 8px;
            right: 20px;
        }

        .MuiAvatar-colorDefault {
            width: 72px;
            height: 72px;
            margin-bottom: 16px;
        }

        h5 {
            margin-bottom: 0;
            color: $black;
        }

        .email-container, .phone-container, .full-address-container {
            margin-top: 8px;
            display: flex;
            gap: 12px;
        }
    }
}

.recipient-card {
    .block > .block-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 20px;

        .MuiIconButton-root {
            position: absolute;
            top: 8px;
            right: 20px;
        }

        .MuiAvatar-colorDefault {
            width: 72px;
            height: 72px;
            margin-bottom: 10px;
        }

        h5 {
            margin-bottom: 0;
            color: $black;
            font-weight: 500;
            font-size: $h3-size;
            line-height: $h3-line-height;
        }

        .email-container, .phone-container, .full-address-container {
            margin-top: 8px;
            display: flex;
            align-items: center;
            gap: 10px;
            color: $black;
            font-size: 14px;
            line-height: 16px;
        }
    }

    &.compact {
        .block > .block-content {
            padding: 5px 0;

            .MuiButtonBase-root {
                display: none;
            }

            .MuiAvatar-colorDefault {
                width: 20px;
                height: 20px;
                margin-right: 10px;
                font-size: 1rem;
            }

            h5 {
                font-size: $h4-size;
                line-height: $h4-line-height;
            }
        }
    }
}
