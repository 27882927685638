.form-control.due_by {
    border-radius: 10px;
    border: $grey-border;
}

.DayPickerInput {
    width: 100%;
    border-radius: 10px;

    input {
        color: $lighter-black;
        background-color: white;
        width: 100%;
        border: none;

        &::placeholder {
            font-family: $subheading-font-family;
            font-style: normal;
            font-weight: normal;
            font-size: $placeholder-font-size;
            line-height: $placeholder-line-height;
            letter-spacing: 0.01em;
            color: $placeholder-color;
        }
    }

    & > div.DayPickerInput-OverlayWrapper {
        z-index: 100000000;
    }
}
