body {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.inline-agreement-fields {
    &.is-invalid {
        border-color: $lighter-white;
        background-color:$lighter-white;
        color: $lighter-black;
    }
}

div.block {
    .block-header {
        background-color: $brand-primary;

        .block-title {
            color: $white;
        }
    }
}
