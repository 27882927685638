.login-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100vw;

    @include respond_to_lg {
        width: 480px;
    }

    @include respond_to_sm {
        padding: 0 20px;
    }

    .subheading, .no-account {
        font-family: $subheading-font-family;
        font-style: normal;
        font-weight: normal;
        font-size: $subheading-font-size;
        line-height: $subheading-font-line-height;
        letter-spacing: 0.01em;
    }

    .google-login-container {
        button {
            width: 100% !important;
            display: flex !important;
            justify-content: center !important;
            border-radius: 10px !important;
        }
    }

    .no-account {
        text-align: center;
        color: $grey-1;
    }
}
