.base-agreement {

    @include respond_to_sm {
        background-color: $navy-seal;
    }

    @include respond_to_md {
        background-color: $navy-seal;
    }

    .form-group.secure_agreement_switch {
        display: flex;
        flex-direction: column;

        span.secure_agreement_switch {
            border: 0;
            margin-left: -12px;
        }
    }

    span.timestamp {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: right;
        letter-spacing: 0.01em;
        color: $grey-1;
    }

    .contract-actions {
        display: flex;
        gap: 10px;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        @include respond_to_smaller_than_desktop() {
            width: 100%;
            padding: 20px 0;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-start;
            align-content: flex-start;

            .btn {
                align-self: flex-start;
                width: 100%;
            }
        }
    }

    div.agreement-id {
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
    }

    h2.agreement-title {
        font-size: 36px;
        line-height: 54px;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        margin-bottom: 0;
    }

    div.agreement_details {
        font-size: $h4-size;
        line-height: 19px;
        display: flex;
        align-items: center;
        margin: 0 0 30px 0;
        color: $grey-1;
    }

    .recipient-details {
        font-size: $h4-size;
        line-height: $h4-line-height;
        letter-spacing: 0.01em;
        color: $neutrals-black;
        font-weight: 500;

        .recipient-name {
            font-size: 14px;
            line-height: 16px;
            font-weight: 500;
        }
    }

    div.or-divider {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 4px 0;
        font-size: 12px;
    }

    a.link.add-new-contact {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        color: $lighter-black;
        font-weight: bold;
        line-height: 24px;
        text-decoration: underline;
    }

    @include respond_to_sm {
        .agreement-amounts {
            display: none;
        }
    }

    @include respond_to_md {
        .agreement-amounts {
            display: none;
        }
    }

    a.link.add-new-contact, a.link.remove-recipient {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        font-weight: bold;
        color: $lighter-black;
        text-decoration: underline;
    }

    .recipient-card {
        .block {
            margin-bottom: 10px;
        }
    }
}
