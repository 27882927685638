.payable-agreement-timeline-container {
    position: relative;

    .middle-line {
        position: absolute;
        height: 28px;
        top: 3px;
        transform: translateY(-50%);
        border-bottom: $grey-border;
        z-index: 1;
        margin-left: 103px;
        width: calc(100% - 206px);

        @include respond_to_smaller_than_desktop() {
            margin-left: 13px;
            width: calc(100% - 26px);
        }
    }

    .payable-agreement-timeline {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        padding: 0 90px 16px 90px;
        position: relative;
        z-index: 2;

        @include respond_to_smaller_than_desktop() {
            padding: 0 0 16px 0;
        }

        .timeline-item {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            gap: 12px;

            font-weight: 700;
            font-size: 10px;
            line-height: 15px;
            /* identical to box height */

            text-align: center;
            letter-spacing: 0.03em;
            text-transform: uppercase;

            /* Neutrals/Grey 1 */

            color: $grey-1;

            .icon-container {
                padding: 9px;
                border-radius: 100%;
                background-color: $grey-1;

                &.completed {
                    background-color: $success-green;
                }

                &.in_progress {
                    background-color: $brand-primary;
                }

                .svg-icon {
                    border-radius: 100%;
                    width: 13px;
                    height: 13px;
                    -webkit-mask-size: 13px 13px;
                    mask-size: 13px 13px;
                    background-color: $white;

                    &:hover, &.highlighted {
                        background-color: $white;
                    }
                }
            }
        }
    }
}


