.form-dialog {
    div.actions {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
}

.MuiDialog-container {
    .MuiDialog-paper {
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
        border-radius: 10px;

        .MuiDialogContent-root.dialog-content-container {
            .MuiTypography-h6 {
                font-family: $heading-font-family;
                font-style: normal;
                font-weight: 700;
                font-size: $h4-size;
                line-height: $h4-line-height;
                color: $grey-5;

                display: flex;
                justify-content: center;
                padding-bottom: 20px;
                text-transform: uppercase;
            }

            p {
                margin-bottom: 0;
            }
        }

        .MuiDialogActions-root {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 20px 8px;

            @include respond_to_smaller_than_desktop() {
                flex-direction: column;
                gap: 12px;
            }

            button.MuiButton-root {
                width: 100%;
                border-radius: 10px;
                font-style: normal;
                font-weight: 700;
                font-size: $button-font-size;
                line-height: $button-line-height;
                padding: 12px 0;

                &.MuiButton-containedPrimary {
                    background-color: $brand-primary;
                    color: $white;
                }

                &.MuiButton-containedSecondary {
                    background-color: $white;
                    color: $danger-red;
                    border: 1px solid $danger-red;
                    box-sizing: border-box;

                    &:hover {
                        color: $white;
                        background-color: $danger-red;
                    }
                }
            }
        }
    }
}
