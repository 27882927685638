div.table-filters {
    padding: 20px 0;

    @include respond_to_smaller_than_desktop {
        padding: 0;
    }

    ul {
        list-style: none;
        display: flex;
        gap: 40px;
        margin-left: -20px;
        flex-direction: row;
        align-content: center;
        align-items: center;

        li {
            font-size: $placeholder-font-size;
            line-height: $table-line-height;
            letter-spacing: 0.01em;
            font-style: normal;
            text-align: center;
            font-weight: 600;
            color: $navy-seal;
            text-decoration: none;

            a {
                color: $navy-seal;
                opacity: 0.5;

                &.active, &:hover {
                    opacity: 1;
                    text-decoration: none;
                    border-bottom: 5px solid $background-color-blue;
                }
            }
        }
    }
}
