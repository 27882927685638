.edit-agreement, .edit-product-payable-agreement {
    @extend .base-agreement;
}

.add-new-milestone-prompt {
    padding: 16px;
    border-radius: 0.75rem;
    border: 1px dotted #cccccc;
    margin-bottom: 20px;

    .foot-note {
        margin-top: 15px;
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 12px;
        text-align: center;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $grey-5;
    }

    @include respond_to_sm {
        .agreement-amounts {
            display: none;
        }
    }

    @include respond_to_md {
        .agreement-amounts {
            display: none;
        }
    }
}

.new-milestone-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    span.circular-btn {
        color: $white;
        width: 30px;
        height: 30px;
        display: flex;
        background: $brand-primary;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
    }
}
