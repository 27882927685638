.wizard_accordion {
    h3 {
        font-size: 15px;
    }

    .wizard_details form {
        width: 100%;
    }

    .wizard {
        &.payable-milestones {
            width: 100%;

            button.add-milestone {
                margin-bottom: 10px;
            }
        }
    }
}