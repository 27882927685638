.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: $heading-font-family;
    color: $heading-color;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 12px;
}

h2, .h2 {
    font-size: $h2-size;
    line-height: $h2-line-height;
    letter-spacing: 0.01em;

    @include respond_to_smaller_than_desktop {
        font-size: $h2-size-mobile;
        line-height: $h2-line-height-mobile;
    }
}

h3, .h3 {
    font-size: $h3-size;
    line-height: $h3-line-height;
    letter-spacing: -0.02em;

    @include respond_to_smaller_than_desktop {
        font-size: $font-size-small;
        line-height: $font-size-small-line-height;
    }
}

h4, .h4 {
    font-size: $h4-size;
    line-height: $h4-line-height;
    letter-spacing: 0.01em;
}
