.received-agreement {
    @extend .base-agreement;

    div.agreement-id {
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
    }

    h2.agreement-title {
        font-size: 36px;
        line-height: 54px;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        margin-bottom: 0;
    }

    div.agreement_details {
        font-size: $h4-size;
        line-height: 19px;
        display: flex;
        align-items: center;
        margin: 0 0 30px 0;
        color: $grey-1;
    }

    .agreement-actions {
        display: flex;
        gap: 10px;
        justify-content: flex-end;;

        @include respond_to_sm() {
            padding: 20px 0;
            flex-direction: column;

            .btn {
                width: 100%;
            }
        }
    }
}
