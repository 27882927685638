.signup-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100vw;

    @include respond_to_lg {
        width: 480px;
    }

    @include respond_to_sm {
        padding: 0 20px;
    }

    .already-have-an-account {
        text-align: center;
        color: $grey-1;
    }

    .google-login-container {
        button {
            width: 100% !important;
            display: flex !important;
            justify-content: center !important;
            border-radius: 10px !important;
        }
    }
}
