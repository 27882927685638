@import "./variables";
@import "./base";
@import "./mixins";

@import "layouts/non-logged-in";

@import "./components/common";
@import "./components/headings";
@import "./components/page-title";
@import "./components/inputs";
@import "./components/forms";
@import "./components/date-picker";
@import "./components/nav";
@import "./components/table";
@import "./components/cards";
@import "./components/wizard";
@import "./components/timelines";
@import "./components/dialog";
@import "./components/icons";
@import "./components/badges";
@import "./components/buttons";
@import "./components/blocks";
@import "./components/milestone";
@import "./components/filters";
@import "./components/svg-icons";
@import "./components/pagination";
@import "./components/alert";
@import "./components/pills";
@import "./components/rhc-container";
@import "./components/block-card-actions";

@import "./pages/dashboard";
@import "./pages/login";
@import "./pages/forgot-password";
@import "./pages/sign-up";
@import "./pages/common";
@import "./pages/profile";
@import "./pages/incomingPayableAgreement";
@import "./pages/base-agreement";
@import "./pages/create-agreement";
@import "./pages/edit-agreement";
@import "./pages/agreement-list";
@import "./pages/recipient";
@import "./pages/error-page";

#popoverDiv {
    width: fit-content;
    margin: auto;
}
