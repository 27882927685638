.contact-details {
  h3 {
    color: $neutrals-black;
    font-weight: 500;
  }
  li {
    color: $grey-1;
    font-size: 14px;
    line-height: 16px;

    i {
      margin-right: 10px;
    }
  }
}