.badge {
  font-weight: 700;
  font-size: $badge-font-size;
  line-height: $badge-line-height;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  padding: 4px 25px;

  &.badge-primary {
    color: $accent;
    background: rgba(130, 128, 235, 0.3);
  }

  &.badge-success {
    color: $success-green;
    background: rgba(76, 175, 80, 0.3);;
  }
}