input {
    &[type=text], &[type=password], &[type=email], &[type=number] {
        border: $grey-border;
        box-sizing: border-box;
        border-radius: 10px;

        &::placeholder {
            font-family: $subheading-font-family;
            font-style: normal;
            font-weight: normal;
            font-size: $placeholder-font-size;
            line-height: $placeholder-line-height;
            letter-spacing: 0.01em;
            color: $placeholder-color;
        }

        &:disabled {
            border-color: rgba(151, 151, 151, 0.5);
            background-color: rgba(151, 151, 151, 0.5);
            color: $white;

            &::placeholder {
                color: $white;
            }
        }
    }

    &.MuiInputBase-input, &.MuiInput-input {
        border: 0;
        box-sizing: content-box;
        border-radius: 0;
        display: flex;
    }
}

textarea {
    border: $grey-border;
    box-sizing: border-box;
    border-radius: 10px;

    &::placeholder {
        font-family: $subheading-font-family;
        font-style: normal;
        font-weight: normal;
        font-size: $placeholder-font-size;
        line-height: $placeholder-line-height;
        letter-spacing: 0.01em;
        color: $placeholder-color;
    }

    &:disabled {
        border-color: $grey-1;
        background-color: $grey-1;
        color: $white;

        &::placeholder {
            color: $white;
        }
    }
}

.form-group {
    .MuiFormControl-root {
        display: flex;
    }

    &.text-area-fh {
        display: flex;
        flex-direction: column;
        height: 100%;

        textarea {
            height: 100%;
            width: 100%;
        }
    }

    .input-group {
        .input-group-text {
            border: $grey-border;
            box-sizing: border-box;

            &:first-child {
                border-radius: 10px 0 0 10px;
                border-right: 0;
                color: $placeholder-color;
            }

            &:last-child {
                border-radius: 0 10px 10px 0;
                border-left: 0;
                color: $placeholder-color;
            }
        }
    }
}
