.ps-non-logged-in-layout {
    background: $white;
    margin: 0;

    .content-col {
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: center;
        align-items: center;

        @include respond_to_lg {
            width: 480px;
        }

        @include respond_to_sm {
            padding: 0 20px;
        }

        h2 {
            margin: 0;
            padding-bottom: 4px;
        }

        .subheading {
            padding-bottom: 32px;
        }

        .logo-container {
            position: relative;
            padding: 0 0 16px;
            left: 0;
            top: 0;

            @include respond_to_smaller_than_desktop {
                display: block;
            }

            img.logo {
                height: 75px;
                width: auto;
                align-content: flex-start;
            }
        }
    }

    .image-col {
        height: 100vh;
        background: url("../images/dog-hero.jpg") #E9E9E9 no-repeat bottom;
        background-size: cover;
    }
}
