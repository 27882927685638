i.svg-icon {
  display: block;
  width: 24px;
  height: 24px;
  mask-size: 24px 24px;
  background-color: $grey-5;

  &.svg-2x {
    width: 48px;
    height: 48px;
    mask-size: 48px 48px;
  }

  &.mini {
    width: 12px;
    height: 12px;
    mask-size: 12px 12px;
  }

  &:hover, &.highlighted {
    background-color: $background-color-blue;
  }

  &.danger {
    &:hover, &.highlighted {
      background-color: $danger-red;
    }

    background-color: $danger-red;
  }

  &.grey-2 {
    background-color: $grey-2;
    // TODO hover state?
  }

  &.grey-3 {
    background-color: $grey-3;
    // TODO hover state?
  }

  &.add-icon {
    mask-image: url('../icons/add-icon.svg');
    -webkit-mask-image: url('../icons/add-icon.svg');
  }

  &.tick {
    width: 34px;
    height: 24px;
    mask-image: url('../icons/tick.svg');
    -webkit-mask-image: url('../icons/tick.svg');
  }

  &.trash {
    mask-image: url('../icons/trash.svg');
    -webkit-mask-image: url('../icons/trash.svg');
  }

  &.edit {
    mask-image: url('../icons/edit.svg');
    -webkit-mask-image: url('../icons/edit.svg');
  }

  &.clock {
    mask-image: url('../icons/clock.svg');
    -webkit-mask-image: url('../icons/clock.svg');
  }

  &.phone {
    mask-image: url('../icons/phone.svg');
    -webkit-mask-image: url('../icons/phone.svg');
  }

  &.hourglass {
    mask-image: url('../icons/hourglass.svg');
    -webkit-mask-image: url('../icons/hourglass.svg');
  }

  &.minus {
    mask-image: url('../icons/minus.svg');
    -webkit-mask-image: url('../icons/minus.svg');
  }

  &.address {
    mask-image: url('../icons/address.svg');
    -webkit-mask-image: url('../icons/address.svg');
  }

  &.contact {
    mask-image: url('../icons/contact.svg');
    -webkit-mask-image: url('../icons/contact.svg');
  }

  &.email {
    mask-image: url('../icons/email.svg');
    -webkit-mask-image: url('../icons/email.svg');
  }

  &.contacts {
    mask-image: url('../icons/contacts.svg');
    -webkit-mask-image: url('../icons/contacts.svg');
  }

  &.dashboard {
    mask-image: url('../icons/dashboard.svg');
    -webkit-mask-image: url('../icons/dashboard.svg');
  }

  &.highlighted-contract {
    mask-image: url('../icons/highlighted-contract.svg');
    -webkit-mask-image: url('../icons/highlighted-contract.svg');
  }

  &.smart-contracts {
    mask-image: url('../icons/smart-contracts.svg');
    -webkit-mask-image: url('../icons/smart-contracts.svg');
  }

  &.transactions {
    mask-image: url('../icons/transactions.svg');
    -webkit-mask-image: url('../icons/transactions.svg');
  }

  &.user {
    mask-image: url('../icons/user.svg');
    -webkit-mask-image: url('../icons/user.svg');
  }

  &.bank {
    mask-image: url('../icons/bank.svg');
    -webkit-mask-image: url('../icons/bank.svg');
  }

  &.shuffle {
    mask-image: url('../icons/shuffle.svg');
    -webkit-mask-image: url('../icons/shuffle.svg');
  }

  &.plus {
    mask-image: url('../icons/plus.svg');
    -webkit-mask-image: url('../icons/plus.svg');
  }

  &.notification {
    mask-image: url('../icons/notification.svg');
    -webkit-mask-image: url('../icons/notification.svg');
  }

  &.shop {
    mask-image: url('../icons/shop.svg');
    -webkit-mask-image: url('../icons/shop.svg');
  }

  &.truck {
    mask-image: url('../icons/truck.svg');
    -webkit-mask-image: url('../icons/truck.svg');
  }

  &.lock {
    mask-image: url('../icons/lock.svg');
    -webkit-mask-image: url('../icons/lock.svg');
  }

  &.cross {
    mask-image: url('../icons/cross.svg');
    -webkit-mask-image: url('../icons/cross.svg');
  }

  &.coin {
    mask-image: url('../icons/coin.svg');
    -webkit-mask-image: url('../icons/coin.svg');
  }

  &.question {
    mask-image: url('../icons/question.svg');
    -webkit-mask-image: url('../icons/question.svg');
  }

  &.comments {
    mask-image: url('../icons/comments.svg');
    -webkit-mask-image: url('../icons/comments.svg');
  }

  &.paper-clip {
    mask-image: url('../icons/paper-clip.svg');
    -webkit-mask-image: url('../icons/paper-clip.svg');
  }
}

.nav-main-link {
  & > i.svg-icon {
    margin-right: 8px;
  }

  &:hover {
    background-color: $white;
    & > span {
      color: $background-color-blue;
    }
    & > i.svg-icon {
      background-color: $background-color-blue;;
    }
  }
}

.MuiIconButton-root {
  border-radius: none;
}
