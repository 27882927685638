#page-container.show-loader {
    .logo-container {
        display: none;
    }
}

.logo-container {
    @include respond_to_smaller_than_desktop {
        display: none;
    }

    display: flex;
    position: absolute;
    left: 12px;
    top: 12px;
    z-index: 9999;

    img.logo {
        width: auto;
        height: 40px;
    }
}


nav#sidebar {
    .content-header {
        background-color: $white !important;
        color: $brand-primary;
    }

    #btn-sidebar-close {
        padding: 10px 18px;
    }
}

header#page-header {
    .username-container {
        display: flex;
        justify-content: center;
        grid-gap: 10px;
        gap: 10px;
        align-items: center;

        span.username {
            font-size: $font-size-small;
        }
    }

    @include respond_to_smaller_than_desktop {
        #btn-toggle-sidebar {
            padding: 9px 18px;
        }
    }
}
