.milestone-row {
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;

    &.heading {
        padding: 0 20px 16px 20px;

        @include respond_to_smaller_than_desktop() {
            display: none;
        }

        & > div {
            /* Button */
            font-style: normal;
            font-weight: 700;
            font-size: 10px;
            line-height: 15px;
            letter-spacing: 0.03em;
            text-transform: uppercase;

            /* Neutrals/Grey 2 */
            color: $neutral-grey-2;
        }
    }

    @include respond_to_smaller_than_desktop() {
        &:not(.heading) {
            flex-direction: column;
            gap: 12px;
        }
    }

    .step-number {
        width: 30px;

        @include respond_to_smaller_than_desktop() {
            display: none;
        }
    }

    .title {
        width: 112px;

        h4 {
            font-weight: 500;
            letter-spacing: 0.01em;
            color: $neutrals-black;
        }

        @include respond_to_smaller_than_desktop() {
            display: flex;
            flex-direction: column;
            width: auto;

            h4 {
                margin-bottom: 0;
            }
        }
    }

    .description {
        width: 227px;

        @include respond_to_smaller_than_desktop() {
            display: flex;
            flex-direction: column;
        }
    }

    .due, .fess, .amount {
        width: 90px;
        text-align: right;

        @include respond_to_smaller_than_desktop() {
            display: flex;
            flex-direction: column;
            text-align: left;
        }
    }

    .amount.amended-amount {
        .milestone-amount {
            text-decoration: line-through;
        }

        .amended-amount {
            font-style: oblique;
        }
    }

    .fees {
        .discounted-fee {
            text-decoration: line-through;
        }
    }

    .text-muted {
        font-weight: 400;
        font-size: $placeholder-font-size;
        line-height: $placeholder-line-height;
        letter-spacing: 0.01em;
        color: $grey-1;
    }

    label {
        display: none;
    }

    @include respond_to_sm() {
        label {
            display: block;
        }
    }
}

.milestone-actions {
    gap: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .status-and-actions {
        gap: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;

        @include respond_to_smaller_than_desktop() {
            flex-direction: column;
        }

        .status-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 6px;

            .status-info {
                font-size: 12px;
            }
        }

        .actions {
            display: flex;
            gap: 12px;

            @include respond_to_smaller_than_desktop() {
                flex-direction: column;;
                width: 100%;

                button {
                    width: 100%;
                }
            }
        }
    }

    .connect-a-bank-account-warning {
        flex: 1;
    }
}

.milestone-errors {
    width: 100%;
}

.block-footer.milestone-row-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;

    @include respond_to_smaller_than_desktop() {
        margin-top: 12px;
    }
}

.add-new-milestone-container {
    border: 2px solid $background-color-blue;
    background-color: $white;
    box-sizing: border-box;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    margin-bottom: 30px;

    .block-rounded {
        margin-bottom: 0;
    }

    @include respond_to_smaller_than_desktop() {
        .block-footer.row {
            border-top: unset;
        }
    }

    .footer-content {
        .actions {
            gap: 12px;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: flex-end;
        }
    }
}
